@keyframes pulsate {
    0% {
        transform: scale(1);
        box-shadow: none;
    }

    50% {
        transform: scale(1.05);
        // box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.25);
    }

    100% {
        transform: scale(1);
        box-shadow: none;
    }
}

.loader {
    animation: pulsate 0.8s infinite;
    overflow-x: hidden;
}
