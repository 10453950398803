.mockup {
    padding: 3rem 0;

    @media (max-width: 576px) {
        padding: 1rem 1rem;
    }

    h1 {
        font-weight: bold;
        font-size: 1.4rem;
    }

    p {
        text-align: justify;
        text-justify: inter-word;
    }
}

.choice {
    display: flex;
    // align-items: center;
    flex-direction: column;
    margin-top: 1.5rem;
    width: 100%;

    h3 {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    button {
        margin-right: 0.5rem;
        border: 2px solid var(--color-primary);
        background-color: transparent;
        border-radius: 5px;
        width: fit-content;
        // height: 40px;
        min-width: 60px;
        min-height: 60px;
        cursor: pointer;
        margin-bottom: 1rem;
        color: var(--color-primary);
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        padding: 0.5rem;

        p {
            content: "";
            width: 15px;
            height: 15px;
            margin-top: 0.5rem;
            display: block;
            margin: 0;
            -webkit-box-shadow: 5px 6px 12px 0px rgba(0, 0, 0, 0.17);
            box-shadow: 5px 6px 12px 0px rgba(0, 0, 0, 0.17);
        }
    }
}

.choice__color {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
    flex-wrap: wrap;

    h3 {
        margin-right: 1rem;
        margin-bottom: 0;
    }

    p {
        margin: 0;
        padding: 0;
    }

    button {
        margin-right: 0.5rem;
        border: 1px solid var(--color-primary);
        background-color: transparent;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-bottom: 1rem;
        // display: flex;
        // align-items: center;
        // justify-items: center;
    }
}

.left {
    max-width: 450px;
}

// .color {
//     width: 15px;
//     height: 15px;
//     content: "";
//     border-radius: 5px;
// }

.bbox {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    button {
        margin-right: 1rem;
        border: 1px solid var(--color-primary);
        border-radius: 20px;
        background-color: transparent;
        padding: 0.5rem 2rem;
        min-width: 150px;
        font-weight: 500;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            border: 1px solid var(--color-blue) !important;
            box-shadow: 0 0 10px #007bff;
        }


        @media (max-width: 576px) {
            padding: 0.5rem .5rem;
            font-size: .8rem;
        }

        
    }
}

.price {
    color: var(--color-primary);
    font-weight: bold;
    margin-bottom: -1rem;
}

.size {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 10px;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: .8rem;
    text-align: center;

    &:hover {
        border: 1px solid var(--color-blue);   
    }
}

.color {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    // border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid rgba(190, 190, 190, 0.384);
}

.var {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 1rem;

    p {
        margin: 0;
        font-size: 0.6rem;
        margin-top: 0.2rem;
    }
}

.editor {
    width: 650px;
    height: 700px;
    background-size: cover;
    position: relative;
    background: white !important;

    @media (max-width: 576px) {
        width: 350px;
	height: 400px;
    }
}

.edit_con {
    // background-color: red !important;
    position: absolute;
    top: 55%;
    left: 52%;
    width: 190px;
    height: 350px;
    content-visibility: auto;
    // transform: translate(-50%, -50%);
    // overflow: hidden;
    // z-index: 100000;
}

.overlay_img {
    user-drag: none;
    -webkit-user-drag: none;
    border: 3px solid transparent;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    z-index: 100000;
}

.upload {
}

.guide {
    position: absolute;
    background: var(--color-blue);
    opacity: 0.3;
    // top: 50%;
    // left: 51%;
    // width: 135px;
    // height: 284px;
    // transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 1;
    // border: 2px solid grey;
    content: "";
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    span {
        display: block;
    }
}

.options {
    display: flex;
    align-items: flex-start;
    // justify-content: space-between;
    margin-bottom: 2rem;

    span {
        font-weight: 500;
        cursor: pointer;
        // margin-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
            color: var(--color-blue);
        }
    }
}

.action_button {
    width: 140px;
    height: 126px;
    border: 1px solid rgb(93, 93, 93);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 1rem;
    padding: .5rem;

    &:hover {
        border: 1px solid var(--color-blue);
    }

    span {
        font-size: 1rem;
        font-weight: 500;
    }

    p {
        text-align: center;
        margin: 0;
        color: grey;
    }
}

.action {
    display: flex;
    flex-direction: row;
    text-align: center;

    svg {
        margin-bottom: .5rem;
    }
}

.the_image {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.guide {
    position: absolute;
    background-color: transparent;
    // background: rgba(0, 0, 255, 0.168);
    border: 2px dotted var(--color-blue);
    opacity: 1;
    overflow: visible;
    // top: 50%;
    // left: 51%;
    // width: 135px;
    // height: 284px;
    // transform: translate(-50%, -50%);
    // overflow: hidden;
    z-index: 1;
    // border: 2px solid grey;
    content: "";
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    span {
        display: block;
    }
}

.pa {
    border: 2px solid var(--color-blue);
    background-color: var(--color-blue);
    display: block;
    color: white;
    text-align: center;
    position: absolute;
    top: 100%;
    left: -1%;
    width: 102%;
}

.the_close_button {
    position: absolute;
    left: 96%;
    top: -15%;
    cursor: pointer;
}

.ta {
    padding: 1rem;
    border-radius: 10px;

    &:focus {
        border: 1px solid var(--color-blue);
    }
}

.cart {
    width: 90%;
    // height: 80%;
    // max-width: 100%;
    cursor: pointer;
    padding: 1rem;
}

.cart_wrapper {
    // border: 1px solid rgba(128, 128, 128, 0.332);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid var(--color-blue);
        border-radius: 10px;
    }
}

.print_type {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    span {
        display: block;
        margin-right: 1rem;
        color: grey;
    }

    button {
        background: transparent;
        border: 2px solid grey;
        border-radius: 5px;
        font-weight: 500;
        margin-right: 1rem;
        padding: .5rem 1rem;
        width: 150px;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            border: 2px solid var(--color-blue);
        }
    }
}

.print_type1 {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    span {
        display: block;
        margin-right: 1.5rem;
        color: rgb(12 21 36);
    }

}

.colorssss {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    div {
        width: 20px;
        height: 20px;
        margin-right: .5rem;
        margin-bottom: .5rem;
        border-radius: 5px;
        cursor: pointer;
    }
}