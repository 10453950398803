::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: #335ddc;
  border-radius: 8px;
}



.login_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 5px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 12px;
}
.login_form {
  flex: 1 0 80%;
  max-width: 480px;
  width: 80%;
  padding: 60px;
}
.login_form p {
  margin-bottom: 30px;
  text-align: center;
}
.login_form p1 {
  margin-top: 10px;
}
.login_form p.form_title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}
.login_form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
.login_form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
.login_form_username {
  height: 50px !important;
}
.ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login_form_button {
  width: 100% !important;
  height: 50px !important;
  letter-spacing: 1px !important;
  border-radius: 6px !important;
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  .login_form {
    max-width: 100%;
  }
}
