.learn_more {

    margin-top: 2rem;
    margin-bottom: 2rem;

    @media(max-width: 576px) {
        padding: 0 1rem;
    }

    p {
        // color: grey;

        @media(max-width: 576px) {
         text-align: justify;
        }
    }

    h1 {
        text-align: center;
    }
}

.image {
    margin-top: 2rem;
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(128, 128, 128, 0.366);

    @media(max-width: 576px) {
        max-width: 100%;
        // margin-bottom: 1rem;
        border-radius: 10px;
    }
}