:root {
    --color-dark: #222222;
    --color-light: #fff;
    --color-grey: #8a8a8a;
    --color-primary: #2d4262;
    --color-blue: #007bff;

    --xs: 576px;

    scroll-behavior: smooth !important;
}


html,body{
    overflow-x: hidden !important;
}

body {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    scroll-behavior: smooth !important;
}

.primary-button {
    border-radius: 20px;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-blue);
    cursor: pointer;
    padding: .5rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    transition: all .3s;
}

.primary-button:hover {
    box-shadow: 0 0 10px #007bff;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container {
    max-width: 1280px;
    margin: 0 auto;
}

/* Overrides */

.tui-image-editor-container {
    border-radius: 5px;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-newline {
    display: none !important;
}

.tui-image-editor-header {
    display: none !important;
}

.tui-image-editor-submenu-style {
    display: none !important;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item li {
    margin-bottom: -1rem !important;
}

.ant-switch-inner {
    display: flex !important;
    align-items: center !important;
}

.ant-switch-inner svg {
    width: 18px !important;
    height: 18px !important;
}

.hv-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc {
    display: flex;
    flex-direction: column;
}
.darkmodeh{
color: var(--color-blue);
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}
/* Track */
/* Track */

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ant-checkbox {
    /* border: 1px solid grey !important; */
}