.success {
    padding: 8rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        max-width: 200px;
    }

    h1 {
        margin: 1rem 0;
    }

    button {
        margin-top: 2rem;
    }
}
