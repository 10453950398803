.checkout {
    padding: 4rem 0;

    @media(max-width: 576px) {
        padding: 1rem;
    }

    h1 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 3rem;
    }

    &__right {
        display: flex;
        align-items: center;

        h2 {
            font-size: 1rem;
        }
        img {
            width: 100%;
            max-width: 250px;
            margin-right: 1rem;
            border-radius: 5px;
        }

        &__info {
            display: flex;
            flex-direction: column;
            // color: rgb(216, 125, 125);
        }
    }
}

.color_display {
    width: 20px;
    height: 20px;
    display: inline-block;
    content: "";
    margin-left: 0.5rem;
    border-radius: 100%;
}

.pay {
    background: transparent;
    border-radius: 1px;
    width: 100%;
    border: 1px solid rgb(214, 214, 214);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media(max-width: 576px) {
        padding: 1rem 0rem;
    }

    img {
        cursor: pointer;
    }
}

.crypto {
    background-color: var(--color-primary);
    color: var(--color-blue) !important;
    padding: 0.7rem 5.8rem;
    margin-left: 1rem;
    border-radius: 3px;
}
