.prod {
}

.img {
    width: 100%;
    max-width: 400px;
}

.info {
    span {
        font-weight: bold;
        font-size: 1.2rem;
    }

    p {
        margin-top: 1rem;
    }
}

.color_box {
    width: 40px;
    height: 40px;
    display: block;
    content: "";
    border-radius: 10px;
    margin-left: 1rem;
}

.button_box {
    margin-top: 2rem;
    button {
        // background-color: #1c2e49;
        background-color: grey !important;
        color: white;
        margin-right: 0.1rem;
        cursor: pointer;
        @media (max-width: 576px) {
            max-width: 100%;
            width: 100%;
            margin-top: 0rem;
            
        }
    }
}
@media (max-width: 576px) {
   .info {
	    span {
		font-weight: bold;
		font-size: 0.8rem;
	    }

	    p {
		margin-top: 0.4rem;
	    }
	}

            
 }

